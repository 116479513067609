import api from '../library/axios';

export default {
  getList(query) {
    return api({
      method: 'get',
      url: '/api/workspaces',
      params: query,
    });
  },
  getAllWorkspaces(query) {
    return api({
      method: 'get',
      url: '/api/backoffice/workspaces',
      params: query,
    });
  },
  getWorkspaces() {
    return api({
      method: 'get',
      url: '/api/backoffice/workspaces/pic',
    });
  },
  create({ name, ws_product_id }) {
    return api({
      method: 'post',
      url: '/api/workspaces',
      data: {
        name,
        ws_product_id,
      },
    });
  },
  update(id, data) {
    return api({
      method: 'put',
      url: `/api/backoffice/${id}/workspaces`,
      data,
    });
  },
  approval(workspace_id, company_id, data) {
    return api({
      method: 'post',
      url: `/api/backoffice/${workspace_id}/${company_id}/workspaces/approval`,
      data,
    });
  },
  pricing_plan(workspace_id, company_id) {
    return api({
      url: `/api/backoffice/${workspace_id}/${company_id}/workspaces/pricing_plan`,
    });
  },
  bot_questions({ id, company_id }) {
    return api({
      url: `/api/workspaces/${id}/${company_id}/questions`,
    });
  },
  update_bot_questions({ id, company_id, data }) {
    return api({
      method: 'patch',
      url: `/api/workspaces/${id}/${company_id}/questions`,
      data,
    });
  },
  work_hour({ id, company_id }) {
    return api({
      url: `/api/workspaces/${id}/${company_id}/work-hour`,
    });
  },
  update_work_hour({ id, company_id, data }) {
    return api({
      method: 'patch',
      url: `/api/workspaces/${id}/${company_id}/work-hour`,
      data,
    });
  },
  auto_reminder({ id, company_id }) {
    return api({
      url: `/api/workspaces/${id}/${company_id}/auto-reminder`,
    });
  },
  update_auto_reminder({ id, company_id, data }) {
    return api({
      method: 'patch',
      url: `/api/workspaces/${id}/${company_id}/auto-reminder`,
      data,
    });
  },
  check_balance({ id, company_id }) {
    return api({
      url: `/api/workspaces/${id}/${company_id}/balance`,
    });
  },
  updateCallFeature({ id, company_id, data }) {
    return api({
      method: 'put',
      url: `/api/backoffice/${id}/${company_id}/workspaces/call_feature`,
      data,
    });
  },
};
