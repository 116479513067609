/* eslint-disable quotes */
import axios from 'axios';
import config from '../config';
import ls from '../library/localStorage';

const { backOfficeUrl } = config.api;
const axiosInstance = axios.create({
  baseURL: backOfficeUrl,
});
export default {
  userActivities(pageNumber) {
    return axiosInstance.get(`/backoffice/log-user-activities?page=${pageNumber}`, {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
  getList(page, search, company_id) {
    return axiosInstance.get('/backoffice/log-user-activities', {
      params: {
        page,
        search,
        company_id,
      },
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
  getCompanies(page, search_by, search_value) {
    return axiosInstance.get('/backoffice/company', {
      params: {
        page,
        search_by,
        search_value,
      },
    });
  },
};
