import ls from '../../library/localStorage';

export default {
  namespaced: true,
  state: {
    companies: ls.get('companies') || [],
    activeCompany: ls.get('activeCp') || {},
  },
  getters: {
    companiesLoaded(state) {
      return !!state.companies;
    },
    hasCompanies(state) {
      return !!(state.companies && state.companies.length);
    },
  },
  mutations: {
    clearActiveCompany(state) {
      state.activeCompany = {};
    },
    setActiveCompany(state, company) {
      state.activeCompany = company;
    },
  },
  actions: {
    clearCompanies({ commit }) {
      commit('clearCompanies');
      ls.remove('companies');
    },
    setActiveCompany({ commit }, company) {
      commit('setActiveCompany', company);
      ls.set('activeCp', company);
    },
    clearActiveCompany({ commit }) {
      commit('clearActiveCompany');
      ls.remove('activeCp');
    },
  },
};
