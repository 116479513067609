import backOffice from '../../api/backOffice';
import log from '../../api/log';
import ls from '../../library/localStorage';

export default {
  namespaced: true,
  state: {
    token: ls.get('token') || null,
    userProfile: ls.get('userProfile') || {},
    companies: ls.get('companies') || [],
    userActivities: ls.get('userActivities') || [],
    workspaces: ls.get('workspaces') || [],
    activeCompany: ls.get('active_company') || {},
    companiesSize: ls.get('companiesSize') || null,
  },
  getters: {
    isLogin: (state) => !!state.token,
    token: (state) => state.token,
    companiesLoaded(state) {
      return !!state.companies;
    },
    workspacesLoaded(state) {
      return !!state.workspaces;
    },
  },
  mutations: {
    setLogin(state, [token, userProfile]) {
      state.token = token;
      state.userProfile = userProfile;
    },
    setLogout(state) {
      state.token = null;
      state.userProfile = null;
    },
    setCompanyProfile(state, data) {
      state.companyProfile = data;
    },
    setCompanies(state, companies) {
      state.companies = companies;
    },
    setUserActivities(state, userActivities) {
      state.userActivities = userActivities;
    },
    setWorkspaces(state, workspaces) {
      state.workspaces = workspaces;
    },
    clearWorkspaces(state) {
      state.workspaces = [];
    },
    setActiveCompany(state, activeCompany) {
      state.activeCompany = activeCompany;
    },
    clearActiveCompany(state) {
      state.activeCompany = [];
    },
    setCompaniesSize(state, companiesSize) {
      state.companiesSize = companiesSize;
    },
    setUserActivitiesSize(state, userActivitiesSize) {
      state.userActivitiesSize = userActivitiesSize;
    },
  },
  actions: {
    setActiveCompany({ commit }, activeCompany) {
      commit('setActiveCompany', activeCompany);
      ls.set('active_company', activeCompany);
    },
    clearActiveCompany({ commit }) {
      commit('clearActiveCompany');
      ls.remove('activityCompany');
    },
    clearWorkspaces({ commit }) {
      commit('clearWorkspaces');
      ls.remove('workspaces');
    },
    doLogin({ commit }, { captcha_token, token }) {
      return new Promise((resolve = () => {}, reject = () => {}) => {
        backOffice
          .login(captcha_token, token)
          .then(async (res) => {
            if (res.data.data.token && res.data.data.users) {
              res.data.data.users.permissions = res.data.data.permissions;
              await commit('setLogin', [res.data.data.token, res.data.data.users]);
              ls.set('token', res.data.data.token);
              ls.set('userProfile', res.data.data.users);
            }
            resolve(res);
          }).catch((err) => {
            reject(err);
          });
      });
    },
    doLogout({ commit, dispatch }) {
      return new Promise((resolve = () => {}) => {
        dispatch('workspace/clearWorkspaces', null, { root: true });
        ls.remove('token');
        ls.remove('userProfile');
        ls.remove('companyProfile');
        ls.remove('activeWs');
        ls.clear();
        commit('setLogout');
        resolve();
      });
    },
    loadCompanies({ commit }, { pageNumber, companyName }) {
      const page = pageNumber || 1;
      if (companyName && companyName.length > 0) {
        return new Promise((resolve = () => {}, reject = () => {}) => {
          backOffice.companies(page, companyName)
            .then(async (res) => {
              if (!res.error) {
                commit('setCompanies', res.data.data.rows);
                ls.set('companies', res.data.data.rows);

                await commit('setCompaniesSize', res.data.data.count);
                await ls.set('companiesSize', res.data.data.count);
                resolve(res);
              }
            }).catch((err) => {
              console.log(err);
              reject(err);
            });
        });
      }
      return new Promise((resolve = () => {}, reject = () => {}) => {
        backOffice.companies(page)
          .then(async (res) => {
            if (!res.error) {
              commit('setCompanies', res.data.data.rows);
              ls.set('companies', res.data.data.rows);

              await commit('setCompaniesSize', res.data.data.count);
              await ls.set('companiesSize', res.data.data.count);
              resolve(res);
            }
          }).catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    loadWorkspaces({ commit }, company_id) {
      return new Promise((resolve = () => {}, reject = () => {}) => {
        backOffice.workspaces(company_id)
          .then((res) => {
            if (!res.error) {
              commit('setActiveCompany', company_id);
              ls.set('active_company', company_id);
              commit('setWorkspaces', res.data.data.rows);
              ls.set('workspaces', res.data.data.rows);
              resolve(res);
            }
          }).catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    loadUserActivities({ commit }, { pageNumber }) {
      const page = pageNumber || 1;
      return new Promise((resolve = () => {}, reject = () => {}) => {
        log.userActivities(page)
          .then((res) => {
            if (!res.error) {
              commit('setUserActivities', res.data.data.rows);
              ls.set('userActivities', res.data.data.rows);

              commit('setUserActivitiesSize', res.data.data.count);
              ls.set('userActivitiesSize', res.data.data.count);
              resolve(res);
            }
          }).catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
};
