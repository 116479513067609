import prod from './production';
import dev from './development';
import venteny from './venteny';
import local from './local';
import mariworks from './mariworks';
import uat from './uat';
import wappin from './wappin';

const env = process.env.VUE_APP_DEPLOY_SERVER;
/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-var */
var config = dev;
if (env === 'production') {
  config = prod;
} else if (env === 'venteny') {
  config = venteny;
} else if (env === 'local') {
  config = local;
} else if (env === 'mari') {
  config = mariworks;
} else if (env === 'uat') {
  config = uat;
} else if (env === 'wappin') {
  config = wappin;
}
export default config;
