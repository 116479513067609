/* eslint-disable quotes */
import axios from 'axios';
import config from '../config';
// import RSA from '../library/rsa-encrypt';
import ls from '../library/localStorage';

const { backOfficeUrl } = config.api;
const axiosInstance = axios.create({
  baseURL: backOfficeUrl,
});
export default {
  login(captcha_token, token) {
    return axiosInstance.post('/backoffice/users/login', {
      captcha_token,
      token,
    });
  },
  companies(pageNumber, companyName) {
    if (companyName) {
      return axiosInstance.get(`/backoffice/company?page=${pageNumber}&search_by=company_name&search_value=${companyName}`, {
        headers: {
          Authorization: `Bearer ${ls.get('token')}`,
        },
      });
    }
    return axiosInstance.get(`/backoffice/company?page=${pageNumber}`, {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
  workspaces(company_id) {
    return axiosInstance.get(`/backoffice/workspaces?company_id=${company_id}`, {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
};
