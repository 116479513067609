import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import ui from './modules/ui';
import workspace from './modules/workspace';
import company from './modules/company';
import report from './modules/report';
import channels from './modules/channels';
import backOffice from './modules/backOffice';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    ui,
    workspace,
    company,
    report,
    channels,
    backOffice,
  },
});
